import React, { SVGProps, useId } from 'react';

export const Spaces = (props: SVGProps<SVGSVGElement>) => {
  const clipPathId = useId();

  return (
    <svg viewBox="0 0 16 16" {...props}>
      <g clip-path="url(#clip0_4301_103198)">
        <path
          d="M0.00186623 1.8C0.00186623 0.805887 0.807753 0 1.80187 0H5.30187C6.29598 0 7.10187 0.805887 7.10187 1.8V5.3C7.10187 6.29412 6.29598 7.1 5.30187 7.1H1.80186C0.807752 7.1 0.00186623 6.29411 0.00186623 5.3V1.8Z"
          fill="currentColor"
        />
        <path
          d="M0 10.7C0 9.70591 0.805888 8.90002 1.8 8.90002H5.3C6.29411 8.90002 7.1 9.70591 7.1 10.7V14.2C7.1 15.1941 6.29411 16 5.3 16H1.8C0.805885 16 0 15.1941 0 14.2V10.7Z"
          fill="currentColor"
        />
        <path
          d="M10.7 8.90002C9.70591 8.90002 8.90002 9.70591 8.90002 10.7V14.2C8.90002 15.1941 9.70591 16 10.7 16H14.2C15.1941 16 16 15.1941 16 14.2V10.7C16 9.70591 15.1941 8.90002 14.2 8.90002H10.7Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.90237 1.8C8.90237 0.805886 9.70826 0 10.7024 0H14.2C15.1941 0 16 0.805888 16 1.8V5.3C16 6.29411 15.1941 7.1 14.2 7.1H10.7024C9.70826 7.1 8.90237 6.29411 8.90237 5.3V1.8ZM10.7024 1.8L14.2 1.8V5.3L10.7024 5.3V1.8Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipPathId}>
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
