import { SVGProps } from 'react';

export const TimeUpcoming = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.6641 11.1094C13.4987 11.6658 14 12.6025 14 13.6055V14C14 15.1046 13.1046 16 12 16H4C2.89543 16 2 15.1046 2 14V13.6055C2 12.6025 2.5013 11.6658 3.3359 11.1094L6.5 9C6.52594 8.9868 6.53902 8.97262 6.56374 8.95751L6.63703 8.90865C6.91755 8.70529 7.1 8.37493 7.1 8.00195C7.1 7.56322 6.86757 7.18346 6.5 7L3.3359 4.8906C2.5013 4.3342 2 3.39751 2 2.39445V2C2 0.895431 2.89543 0 4 0H12C13.1046 0 14 0.895431 14 2V2.39445C14 3.39751 13.4987 4.3342 12.6641 4.8906L9.5 7C9.13243 7.18346 8.9 7.56322 8.9 8.00195C8.9 8.37492 9.08245 8.70527 9.36296 8.90864L9.43628 8.95752C9.46099 8.97263 9.47407 8.9868 9.5 9L12.6641 11.1094ZM8 10.1633L11.6656 12.6071C11.9995 12.8296 12.2 13.2043 12.2 13.6055V14C12.2 14.1105 12.1105 14.2 12 14.2L4 14.2C3.88954 14.2 3.8 14.1105 3.8 14V13.6055C3.8 13.2043 4.00052 12.8296 4.33436 12.6071L8 10.1633Z"
        fill="currentColor"
      />
    </svg>
  );
};
