import { SVGProps } from 'react';

export const UnderValidation = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 15 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4084 2H11.8C13.2912 2 14.5 3.20883 14.5 4.7V13.3C14.5 14.7912 13.2912 16 11.8 16H3.2C1.70883 16 0.5 14.7912 0.5 13.3V4.7C0.5 3.20883 1.70883 2 3.2 2H3.59162C3.89996 0.848178 4.95093 0 6.2 0H8.8C10.0491 0 11.1 0.848178 11.4084 2ZM5.29999 2.69999C5.29999 2.20293 5.70293 1.79999 6.19999 1.79999H8.79999C9.29704 1.79999 9.69999 2.20293 9.69999 2.69999C9.69999 3.19704 9.29704 3.59999 8.79999 3.59999H6.19999C5.70293 3.59999 5.29999 3.19704 5.29999 2.69999ZM11.3864 7.55584C11.7378 7.20436 11.7378 6.63452 11.3864 6.28304C11.0349 5.93157 10.4651 5.93157 10.1136 6.28304L6.24998 10.1466L4.88637 8.78304C4.5349 8.43157 3.96505 8.43157 3.61358 8.78304C3.26211 9.13452 3.26211 9.70436 3.61358 10.0558L5.61358 12.0558C5.78236 12.2246 6.01128 12.3194 6.24998 12.3194C6.48867 12.3194 6.71759 12.2246 6.88637 12.0558L11.3864 7.55584Z"
        fill="#CF9011"
      />
    </svg>
  );
};
