import { SVGProps } from 'react';

export const TimeOngoing = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14 2.39445C14 3.39751 13.4987 4.3342 12.6641 4.8906L9.5 7C9.48504 7.00761 9.47437 7.01555 9.46326 7.0238C9.45514 7.02984 9.4467 7.03611 9.43627 7.04248L9.36296 7.09136C9.08244 7.29473 8.9 7.62508 8.9 7.99805L8.89937 7.99906L8.9 8C8.9 8.37297 9.08245 8.70333 9.36297 8.9067L9.43626 8.95555C9.44671 8.96194 9.45508 8.96816 9.46322 8.97421C9.47433 8.98248 9.48503 8.99043 9.5 8.99805L12.6641 11.1074C13.4987 11.6638 14 12.6005 14 13.6036V13.998C14 15.1026 13.1046 15.998 12 15.998L4 15.998C2.89543 15.998 2 15.1026 2 13.998V13.6036C2 12.6005 2.5013 11.6638 3.3359 11.1074L6.5 8.99805C6.51497 8.99043 6.52565 8.98249 6.53676 8.97423C6.5449 8.96818 6.55327 8.96196 6.56372 8.95557L6.63704 8.90668C6.91756 8.70332 7.1 8.37297 7.1 8L7.10063 7.99907L7.1 7.99805C7.1 7.62507 6.91755 7.29471 6.63703 7.09135L6.56374 7.04249C6.55329 7.0361 6.54492 7.02988 6.53678 7.02383C6.52566 7.01557 6.51497 7.00762 6.5 7L3.3359 4.8906C2.5013 4.3342 2 3.39751 2 2.39445V2C2 0.895432 2.89543 0 4 0H12C13.1046 0 14 0.89543 14 2V2.39445ZM11.355 3.60001L11.6656 3.39291C11.9995 3.17035 12.2 2.79567 12.2 2.39445V2C12.2 1.88954 12.1105 1.8 12 1.8L4 1.8C3.88954 1.8 3.8 1.88954 3.8 2V2.39445C3.8 2.79567 4.00052 3.17035 4.33436 3.39291L4.645 3.60001H11.355ZM11.3129 12.37H4.68708L8 10.1614L11.3129 12.37Z"
        fill="currentColor"
      />
    </svg>
  );
};
