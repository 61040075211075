import { SVGProps } from 'react';

export const Time = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.6641 4.8906C13.4987 4.3342 14 3.39751 14 2.39445V2C14 0.89543 13.1046 0 12 0H4C2.89543 0 2 0.895432 2 2V2.39445C2 3.39751 2.5013 4.3342 3.3359 4.8906L6.5 7C6.52594 7.0132 6.53902 7.02738 6.56374 7.04249L6.63703 7.09135C6.91755 7.29471 7.1 7.62507 7.1 7.99805C7.1 8.43678 6.86757 8.81654 6.5 9L3.3359 11.1094C2.5013 11.6658 2 12.6025 2 13.6055V14C2 15.1046 2.89543 16 4 16H12C13.1046 16 14 15.1046 14 14V13.6056C14 12.6025 13.4987 11.6658 12.6641 11.1094L9.5 9C9.13243 8.81654 8.9 8.43678 8.9 7.99805C8.9 7.62508 9.08245 7.29473 9.36296 7.09136L9.43628 7.04248C9.46099 7.02737 9.47407 7.0132 9.5 7L12.6641 4.8906ZM8 5.83667L11.6656 3.39291C11.9995 3.17035 12.2 2.79567 12.2 2.39445V2C12.2 1.88954 12.1105 1.8 12 1.8L4 1.8C3.88954 1.8 3.8 1.88954 3.8 2V2.39445C3.8 2.79567 4.00052 3.17035 4.33436 3.39291L8 5.83667Z"
        fill="currentColor"
      />
    </svg>
  );
};
