import { SVGProps } from 'react';

export const TrendDown = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.1 12.0966C5.1 12.5936 5.50294 12.9966 6 12.9966H12.0017C12.554 12.9966 13.0017 12.5488 13.0017 11.9966V5.99657C13.0017 5.49951 12.5987 5.09657 12.1017 5.09657C11.6046 5.09657 11.2017 5.49951 11.2017 5.99657V9.92473L4.5362 3.26343C4.18461 2.91207 3.61477 2.91224 3.2634 3.26383C2.91204 3.61541 2.91222 4.18526 3.2638 4.53662L9.92793 11.1966H6C5.50294 11.1966 5.1 11.5995 5.1 12.0966Z"
        fill="currentColor"
      />
    </svg>
  );
};
